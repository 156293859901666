/*@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@500&display=swap');*/

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: indigo !important;
    background: radial-gradient(circle, rgb(75, 35, 101) 0%, rgb(30, 19, 39) 100%);
    font-family: 'Cinzel', serif;
}


canvas {
    height: 100vh !important;
    width: 100vw !important;

}

h1 {font-size: 3.6em;}

.logo-menu {
  max-width: 120px;
}

.UI {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 99999;
    background-color: rgba(19, 10, 28, 0.8);
    background: radial-gradient(circle, rgba(45, 23, 66, 0.8) 0%, rgb(19, 10, 28, 0.8) 100%);
    margin: 10px 25vw 10px 25vw;
    padding: 60px 10px;
    border-radius: 4px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12),
    0 2px 2px rgba(0, 0, 0, 0.12),
    0 4px 4px rgba(0, 0, 0, 0.12),
    0 8px 8px rgba(0, 0, 0, 0.12),
    0 16px 16px rgba(0, 0, 0, 0.12);
    outline: 1px solid #b8a178;
    outline-offset: -11px;
}

.UICards {

}

.bt {
    background-color: #4b0082;
    background: radial-gradient(circle, rgb(84, 23, 130) 0%, rgb(30, 19, 39) 100%);
    padding: 5px 14px;
    text-align: center;
    color: #b8a178;
    text-shadow: #251831 0px -2px 1px;
    margin: 12px;
    font-size: 1.2rem;
    text-decoration: none;
    letter-spacing: 2px;
    border-radius: 12px;
    border: 2px solid rgba(184, 161, 120, 0.67);
    border-bottom: 5px solid rgba(125, 109, 81, 0.67);
    border-top: 3px solid rgba(234, 217, 171, 0.67);
    outline: 1px solid rgba(207, 191, 173, 0.87);
    outline-offset: -3px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12),
    0 2px 2px rgba(0, 0, 0, 0.12),
    0 4px 4px rgba(0, 0, 0, 0.12),
    0 8px 8px rgba(0, 0, 0, 0.12),
    0 16px 16px rgba(0, 0, 0, 0.12), inset 0px 10px 14px rgba(120, 0, 203, 0.59);
    transition: 1s;
    transition-timing-function: ease;
}

.bt:hover {
    cursor: pointer;
    background-color: #771482;
    background: radial-gradient(circle, rgb(158, 12, 163) 0%, rgb(70, 29, 81) 100%);
    color: #f1d39d;
    outline: 1px solid rgba(255, 235, 216, 0.87);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12),
    0 2px 2px rgba(0, 0, 0, 0.5),
    0 4px 4px rgba(0, 0, 0, 0.12),
    0 8px 8px rgba(0, 0, 0, 0.12),
    0 16px 22px rgba(119, 20, 130, 0.3), inset 0px 10px 14px rgba(163, 0, 232, 0.59);
}

.close-bt {
    width: 46px;
    height: 46px;
    border-radius: 64px;
    margin-top: 20px;
    padding-left: 23px;
    padding-top: 7px;
    background-color: #771482;
    background: radial-gradient(circle, rgb(70, 29, 81) 0%, rgb(34, 16, 46) 100%);
    color: #f1d39d;
    font-size: 2em;
    font-family: 'arial', sans-serif;
    border-top: 3px solid rgba(241, 211, 157, 0.87);
    border-bottom: 5px solid rgba(167, 146, 109, 0.93);
    outline: 1px solid rgba(255, 235, 216, 0.87);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12),
    0 2px 2px rgba(0, 0, 0, 0.5),
    0 4px 4px rgba(0, 0, 0, 0.12),
    0 8px 8px rgba(0, 0, 0, 0.12),
    0 16px 22px rgba(119, 20, 130, 0.3);
}

.close-bt:hover {
    cursor: pointer;
    background-color: #771482;
    background: radial-gradient(circle, rgb(158, 12, 163) 0%, rgb(70, 29, 81) 100%);
    color: #f1d39d;
    outline: 1px solid rgba(255, 235, 216, 0.87);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12),
    0 2px 2px rgba(0, 0, 0, 0.5),
    0 4px 4px rgba(0, 0, 0, 0.12),
    0 8px 8px rgba(0, 0, 0, 0.12),
    0 16px 22px rgba(119, 20, 130, 0.3), inset 0px 10px 14px rgba(163, 0, 232, 0.59);
}

/* Text Resultat Tirage */
#text_res {
    position: absolute;
    width: 100%;
    height: 35vh;
    margin-top: 40vh;
    padding-top: 5vh;
    z-index: 7000;
    background-color: rgba(0,0,0,0.3);
    backdrop-filter: blur(4px) brightness(120%);
    flex-direction: column;
    justify-content: center;

    animation: fadeInBG;
    animation-duration: 6.2s;
    animation-timing-function: ease;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

#text_res_text {
    font-family: 'Cinzel', serif;
    color:#b4a688;
    text-shadow: 5px 5px rgba(30,30,30,0.5);
    letter-spacing: 1.2px;
    font-size:10vw;
    text-align: center;

    animation: fadeIn;
    animation-duration: 6s;
    animation-timing-function: ease;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

#text_res_final {
    position: absolute;
    width: 100%;
    height: 30vh;
    margin-top: 30vh;
    padding-top: 5vh;
    z-index: 7000;
    backdrop-filter: blur(8px) brightness(70%) grayscale(30%);
    border-bottom: 5px solid rgba(125, 109, 81, 0.67);
    border-top: 3px solid rgba(234, 217, 171, 0.67);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12),
    0 2px 2px rgba(0, 0, 0, 0.12),
    0 4px 4px rgba(0, 0, 0, 0.12),
    0 8px 8px rgba(0, 0, 0, 0.12),
    0 16px 16px rgba(0, 0, 0, 0.12);
    flex-direction: column;
    justify-content: center;

    animation: fadeInBG_final;
    animation-duration: 4s;
    animation-timing-function: ease;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

#text_res_text_final {
    font-family: 'Cinzel', serif;
    color:#b4a688;
    text-shadow: 5px 5px rgba(30,30,30,0.5);
    letter-spacing: 1.2px;
    font-size:10vw;
    text-align: center;

    animation: fadeIn_final;
    animation-duration: 4s;
    animation-timing-function: ease;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes fadeInBG {
    0% { opacity: 0; filter: blur(100px); }
    60% { opacity: 1; filter: blur(0px); }
    100% { opacity: 0; filter: blur(100px); }
}

@keyframes fadeIn {
    0% { opacity: 0; filter: blur(100px); top: 100vh;}
    30% { opacity: 1; filter: blur(0px); }
    90% { opacity: 1; filter: blur(0px);  top: 30vh; }
    100% { opacity: 0; filter: blur(100px);  top: -50px;}
}

@keyframes fadeInBG_final {
    0% { opacity: 0; filter: blur(100px); }
    100% { opacity: 1; filter: blur(0px); }
}

@keyframes fadeIn_final {
    0% { opacity: 0; filter: blur(100px); top: 100vh;}
    30% { opacity: 1; filter: blur(0px); }
    100% { opacity: 1; filter: blur(0px);  top: 30vh; }
}


/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {

    /* CSS */

}

/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {

    /* CSS */

}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {

    /* CSS */

}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

    /* CSS */

}

/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {

    /* CSS */

}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {

  h1 {font-size: 1.6em;}

    .bt {
        margin: 8px;
    }

    .UI {
        margin: 10px 5vw 10px 5vw;
        padding: 60px 5px;
    }

    .logo-menu {
      max-width: 80px;
    }

}