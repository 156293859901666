html, body {
    background-color: #333;
    /*background: linear-gradient(180deg, rgba(65,13,128,1) 0%, rgba(11,168,205,1) 100%);*/
    overflow: hidden;
    width: 100%;
    height:100%;
    margin: 0px;
    padding: 0px;
}

canvas {
    width: 100%;
    height:100%;
    touch-action: none;
    filter: contrast(160%)  brightness(90%) saturate(140%);
}

#canvas_texture_linear {
    touch-action: none;

}
